$navLinkColorWhite: #fff;
$navLinkColorBlack: #000;

.white {
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    padding: 15px 20px;
    position: relative;
    color: $navLinkColorWhite;
}

.dark {
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    padding: 15px 20px;
    position: relative;
    color: $navLinkColorBlack
}

.white:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: $navLinkColorWhite;
    transition: width 0.3s ease 0s, left 0.3s ease 0s; 
    width: 0;
}


.dark:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: $navLinkColorBlack;
    transition: width 0.3s ease 0s, left 0.3s ease 0s; 
    width: 0;
}

.highlighted:after {
    width: 100%;
    left: 0;
}

.not-highlighted:after {
    width: 0;
}

.white:hover:after { 
    width: 100%; 
    left: 0; 
}

.dark:hover:after { 
    width: 100%; 
    left: 0; 
}

#logo {
    animation: bounce 2.5s infinite; //other browsers
}