
footer{
    text-align: center;
    padding-bottom: 30px;
    padding-top: 30px;
    margin-top: auto;
}

footer li{
    list-style: none;
    margin: 10px;
    display: inline-block;
}

.menu a{
    color: #000000;
    text-transform: capitalize;
    text-decoration: none;
}
.menu a:hover{
    text-decoration: underline;
    transition: 0.5s;
}
