body {
  background-color: #f6f9fc;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

.spinner {
  width: 25px;
  height: 25px;
  margin: 20px auto;
  border: 4px solid rgba(0, 0, 0, .1);
  border-left: 4px solid #000;
  border-right: 4px solid #000;
  animation: spinner 1s linear infinite forwards;
  -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
       -o-border-radius: 50%;
      -ms-border-radius: 50%;
          border-radius: 50%
}

@keyframes spinner {
  0% {
      transform: rotate(0deg)
  }
  
  100% {
      transform: rotate(360deg)
  }
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

.boring-link {
  color: unset;
  text-decoration: unset;
}