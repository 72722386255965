
.card {
    background-size: cover;
    background-position: center center;
    will-change: transform;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


.button {
    margin-left: auto;
    margin-right: auto;
}
  

@media (max-width: 480px) {
    /* PHONE */
    .project-video {
        width:345px;
        height:236px;
        margin-left: auto;
        margin-right: auto;
    }
    .text {
        text-align: center;
        margin-top: 15px;
        margin-right: 15px;
    }
}

@media (min-width: 480px) {
    /* IPAD & DESKTOP & LAPTOP */
    .project-video {
        width:500px;
        height:281px;
        margin-left: auto;
        margin-right: auto;
    }
    .text {
        text-align: center;
        margin-top: 10px;
        margin-right: 20px;
        margin-left: 20px;
    }
}

.dim {
    opacity: 0.5;
}
.not-dim {
    opacity: 1;
}